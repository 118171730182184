<template>
    <div>
        <el-dialog :visible.sync="sta.show" width="500px">
            <span slot="title">新增/修改 人员</span>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label><b>选择教师</b></label>
                        <el-select v-model="model.TEACHER_ID" filterable placeholder="请选择教师" clearable
                            style="display: block">
                            <el-option :label="it.NAME" :value="it.ID" v-for="it in teachers" :key="it.ID" />
                        </el-select>
                    </div>
                    <div class="form-group">
                        <label><b>选择类型</b></label>
                        <el-select v-model="model.TYPE" placeholder="请选择角色" clearable style="display: block;"
                            @change="changeType">
                            <el-option :label="it.Name" :value="it.Id" v-for="it in types" :key="it.Id" />
                        </el-select>
                    </div>
                    <div class="form-group">
                        <label><b>选择角色</b></label>
                        <el-select ref="roleSelect" v-model="model.ROLE" placeholder="请选择角色" clearable
                            style="display: block;">
                            <el-option :label="it.Key" :value="it.Value" v-for="it in roles" :key="it.Value" />
                        </el-select>
                    </div>
                    <div class="form-group">
                        <label><b>备注</b></label>
                        <el-input type="textarea" v-model="model.NOTE" :rows="3" placeholder="请输入备注" clearable>
                        </el-input>
                    </div>
                </div>
            </div>
            <div slot="footer" v-loading="sta.loading">
                <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
                <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sta: {
                    show: false,
                    loading: false
                },
                model: {},
                teachers: [],
                roles: [],
            }
        },
        props:["types"],
        created() {
            if (this.teachers.length == 0)
                this.getTeachers();

        },
        methods: {
            init(model) {
                this.sta = { show: true, loading: false }

                if (model != null) {
                    this.model = JSON.parse((JSON.stringify(model)));
                    this.roles = this.types.find(x => x.Id == this.model.TYPE).Roles;
                }
                else this.model = {
                    ROLE: ""
                };
            },
            getTypeRoles() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/ORG/MenderApi/GetTypeRoles",
                    completed: function (res) {
                        self.types = res.DATA;
                    }
                })
            },
            changeType(val) {
                this.model.ROLE = "";
                this.roles = this.types.find(x => x.Id == val).Roles;
                this.$refs.roleSelect.$forceUpdate();
            },
            getTeachers() {
                let self = this;
                this.whale.remote.getCollection({
                    url: "/api/School/ORG/TeacherApi/GetList",
                    data: { PAGE_SIZE: 0 },
                    completed: function (its) {
                        self.teachers = its;
                    }
                })
            },
            submit() {
                let tea = this.teachers.find(p => p.ID == this.model.TEACHER_ID);
                if (tea == null || tea == undefined) {
                    this.whale.toast.err("请选择一个教师")
                    return;
                }
                if (this.model.ROLE == "") {
                    this.whale.toast.err("请选择一个角色")
                    return;
                }
                this.model.TEACHER_NAME = tea.NAME;
                this.model.MOBILE = tea.MOBILE;
                let self = this;
                // console.log(self.model)
                self.sta.loading = true;
                this.whale.remote.getResult({
                    url: "/api/School/Org/MenderApi/Save",
                    data: self.model,
                    finally() { self.sta.loading = false; },
                    completed: function () {
                        self.sta.show = false;
                        self.$emit("on-saved");
                    }
                })
            }
        }
    }
</script>